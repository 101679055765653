:root {
    --darkcolor: #00365c ;/* #4169E1;/* #001f3f;/*#002147;/* #001f3f;/* #00008B;
    /*#191970; /*#003153; /*#002147; /*#235a44;GOOD COLORS: SAGE GREEN: #235a44, blue: #003153
    website blue: #1ba1ff*/
    
    --lightercolor: #eaeaea;
    --lightcolor: #FFFFFF;
    --light2color: #a1b5c3;
    --darkercolor: #04273f;
    --hovercolor: #011a2c;
    --hover2color:  #011b2b;
    --fontheader: 240%;
    --space: 90px;
    --navsize: 110%
}

*{
    margin:0;
    padding:0;
    box-sizing:border-box;
    text-decoration: none;
    font-weight: 700;
    
    font-family: "Kadwa", sans-serif, serif, Arial, Helvetica, cursive;
}

/*------------------------------------NAVIGATION BAR CODE STARTS HERE ------------------------------------*/

.nav-text-phone {
    display: none;

}




.links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
}

.content .home .logo a {
    color: var(--lightcolor);
    font-size: 33px;
    font-weight: 600;
}

.content .links li {
    list-style-type: none;
}

.CRlogo {
    height: 50px;
}

.CRlogo:hover, .logo:hover {
    transform: scale(1.05);
    cursor: pointer;
}

.barsicon {
    color: var(--lightcolor);
    font-size: 28px;
    cursor: pointer;
    line-height: 70px;
    width: 70px;
    text-align: center;
    display: none;
}

.content .links li a {
    color: var(--lightcolor);
    font-size: 18px;
    font-weight: 500;
    padding: 9px 40px;
    border-radius: 5px;
    transition: all 0.2s ease;
}

.content .links li a:hover {
    background: var(--hovercolor);
}

.content .home {
    display: flex;
    align-items: flex-start;
    
}



nav {
    background-color: var(--darkercolor);
    position: fixed;
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    height: 70px;
    width: 100%;
    z-index: 9999;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.7);
}

nav .content {
    display: flex;
    align-items: center;
   
}

nav .content .links {
    margin-left: 80px;
    display: flex;
    overflow-y: auto;
    
}

.check {
    display: none;
}



/*DROP DOWN MENU CODE STARTS*/
.content .links li {
    display: flex;
    justify-content: center;

}

.content .links ul {
    position: absolute;
    top: 70px;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    background-color: var(--darkercolor);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.7);
}

.content .links ul li a{
   display: block;
   width: 100%;
   line-height: 30px;
   border-radius: 0px!important;
}

.content .links li:hover ul {
    opacity: 1;
    visibility: visible;
    transition: all 0.3s ease;
}

.content .links li ul {
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
}

/*RESPONSIVE CODE STARTS HERE*/

@media screen and (max-width: 1250px) {
    .wrapper nav {
        max-width:100%;
        padding: 0 20px;
    }

    nav .content .links {
        margin-left: 30px;
    }

    .content .links li a{
        padding: 8px 23px;
    }


}

@media screen and (max-width: 980px) {

  

    nav .content .links {
        display: block;
        box-shadow: 0 8px 10px rgba(0, 0, 0, 0.7);
        position: fixed;
        background-color: var(--darkercolor);
        height: 100%;
        width: 100%;
        top: 70px;
        margin-right: 0;
        max-width: 350px;
        overflow-y: auto;
        transition: all 0.3s ease;
        right: 0;
        padding-bottom: 100px;
    }

    .content .links li {
        margin: 15px 20px;
        flex-direction: column;
    }

    .content .links li a {
        line-height: 40px;
        font-size: 20px;
        display: block;
        padding: 8px 18px;
        text-align: left;
        cursor: pointer;
    }

    .barsicon {
        display: block;
        height: 25px;
    }

    /*DROP DOWN MENU CODE STARTS*/
    .content .links ul {
        position: static;
        opacity: 1;
        visibility: visible;
    }

    .content .links ul li {
        margin: 7px 20px;
    }


    .content .home .logo a { 
        font-size: 20px;
        align-self: flex-end;
    }
    .content .home .CRlogo{ 
        height: 27px;
        align-self: center;
        margin-left: 0;
    }

    .content {
        flex-direction: row-reverse;
        justify-content: space-between;
        width: 100%;
    }


}

@media screen and (min-width: 980px) {
    .links {
        display: flex !important;
        justify-content: space-between;
        align-items: center;
        height: 70px;
    }
}

/*------------------------------------NAVIGATION BAR CODE ENDS HERE ------------------------------------*/

/*FOOTER CODE STARTS HERE*/

.footer-container {
    display: flex;
    flex-direction: column;
    background-color: var(--darkercolor) !important;
    width: 100%;
    
}

.footer-menu-container {
    margin-left: 10%;
    margin-right: 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer-menu-container div h3 {
    color: var(--lightcolor);
    font-size: 150%;
    margin-top: 10px;
}

.footer-menu-container div ul li {
    list-style: none;
    display: block;
    text-align: left;
    margin-top: 10px;
    color: var(--lightcolor);
}

.footer-menu-container div ul li:hover, .footer-menu-container div h3:hover {
    color: var(--hovercolor);
    cursor: pointer;
    transform: scale(1.05);
}

.bottom div p {
    color: var(--lightcolor);
    text-align: center;
    margin-top: 5px;
    font-size: 150%;
}

.bottom p {
    color: var(--lightcolor);
    text-align: center;
    font-size: 100%;
}

@media screen and (max-width: 768px) {
    .footer-menu-container {
        flex-direction: column;
        align-items: center;
    
    }
}
/*------------------------------------------FOOTER CODE ENDS HERE----------------------------------------*/

/*----------------------------------------PAGE1 CODE STARTS HERE ----------------------------------------*/
.opportunity-text {
    margin-left: 10%;
    margin-right: 10%;
    padding-top: 40px;
    text-align: left;
    font-size: var(--fontheader);
    color: var(--darkcolor);
}

.job-search {
    margin-top: 0px;
    padding: 20px;
    text-align: left;
    display: flex;
    background-color: var(--darkcolor);
    margin-left: 10%;
    margin-right: 10%;
}

.search-job {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    margin-right: 10px;
}

.button-adv {
    background-color: var(--darkcolor);
    cursor: pointer;
    padding: 3px;
    color: var(--lightcolor);
}

.button-adv:hover {
    transform: scale(1.03);
}

.button-job {
    background-color: var(--hovercolor);
    color: var(--lightcolor);
}

.button-job:hover, .learn-button:hover {
    cursor: pointer;
    background-color: var(--hover2color); 
    transform: scale(1.02);
  }

  /*SECTION #2*/
  .next-step {
    background-color: var(--darkcolor);
    width: 100%;
    margin-top: 50px;
    padding-top: 20px;
    padding-bottom: 10px;
  }
  .step-text {
    margin-left: 10%;
    margin-right: 10%;
    text-align: right;
    font-size: var(--fontheader);
    color: var(--lightcolor);
  }
  
  .img-container{
    margin-left: 10%;
    margin-right: 10%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .img-container div {
    width: 33.333%;
  }
  .img-container img {
    height: 230px;
    width: 260px;
    object-fit: cover;
    border-radius: 5px;   
    border-color: var(--lightcolor);
    border-width: 6px;
    border-style: solid;
    
  }

  .img-container img:hover{
    cursor: pointer;
    transform: scale(1.02);
    box-shadow: 5px 5px 10px var(--lightcolor);
  }

  .img-container p{
    font-size: 120%;
    color: var(--lightcolor);
    margin-top: 10px;
    margin-bottom: 10px;
  }


  /*CONTACT JS*/


  .img-container2 {
    margin-left: 10%;
    margin-right: 10%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .img-container2 div {
    width: 33.333%;
  }
  
  .img-container2 img {
    height: 230px;
    width: 260px;
    object-fit: cover;
    border-radius: 5px;   
    border-color: var(--darkcolor);
    border-width: 6px;
    border-style: solid;
    
  }

  .img-container2 img:hover {
    cursor: pointer;
    transform: scale(1.02);
    box-shadow: 5px 5px 10px var(--hovercolor);
  }

  .img-container2 p {
    font-size: 120%;
    color: var(--darkcolor);
    margin-top: 10px;
    margin-bottom: 10px;
  }



  /*SECTION #3*/

  .discover-jobs-text, .story-text {
    margin-left: 10%;
    margin-right: 10%;
    text-align: left;
    font-size: var(--fontheader);
    margin-top: 5px;
    color: var(--darkcolor);
  }
  .columns {
    display: flex;
    justify-content: space-between;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 20px;
    padding-bottom: 30px;
  }
  .columns div {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .columns button {
    background-color: var(--lightcolor);
    color: var(--darkcolor);
    margin-top: 20px;
    margin-bottom: 7px;
    border: none;
    font-size: 150%;
  }
    .columns button:hover, .Page1-descr:hover {
        cursor: pointer;
        transform: scale(1.04);
        color: var(--hover2color);
    }

    /*SECTION #4*/
    .talent-content {
        background-color: var(--darkcolor);
    width: 100%;
    padding-top: var(--space);
    padding: 0px;
    }

    .talent-text{
        margin-left: 10%;
        margin-right: 10%;
        padding-top: 10px;
        text-align: right;
        font-size: var(--fontheader);
        color: var(--lightcolor);
    }

    .talent-desc-text {
        padding-top: 10px;
        text-align: right;
        font-size: 120%;
        color: var(--lightcolor);
        margin-bottom: 30px;
    }
    .talent-button {
        background-color: var(--lightcolor);
        color: var(--darkcolor);
        border: none;
        padding: 10px;
        width: 90%;
        height: 20%;
        align-self: center;
        border-radius: 7px;
        font-size: 150%;
        margin-right: 10px;
        margin-bottom: 20px;
    }
    .talent-button:hover {
        cursor: pointer;
        transform: scale(1.02);
        box-shadow: 2px 2px 5px var(--hover2color);
    }
    .talent-container {
        margin-left: 10%;
        margin-right: 10%;
        display: flex;
        justify-content: space-between;
        
    }

     /*SECTION #5*/
     .story-content {
        display: flex;
    padding-top: var(--space);
    padding: 0px;
    margin-left: 10%;
    margin-right: 0;
     }

     .story-content p{
        padding-top: 10px;
        text-align: left;
        font-size: 120%;
        color: var(--darkcolor);
     }

     .story-side {
        width: 50%;
        padding-left: 20px;
     }
   

     .learn-button {
        background-color: var(--darkcolor);
        color: var(--lightcolor);
        border: none;
        padding: 10px;
        width: 50%;
        align-self: center;
        border-radius: 7px;
        font-size: 150%;
        margin-right: 10px;
        margin-top: 20px;
        margin-bottom: 20px;
     }

     .search-job-button {
        background-color: var(--darkcolor);
        display: block !important;
        border: none;
        color: var(--lightcolor);
        font-size: 140%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        padding: 10px;
    }

    .search-job-button:hover {
        cursor: pointer;
        transform: scale(1.02);
        background-color: var(--hovercolor);
    }

     @media screen and (max-width: 980px) {
        .storey-left{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column; 
        }

       
        .job-search {
            display: none !important;
        }
    
    
        .img-container {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 0;
        }

        .columns {
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        .talent-container {
            flex-direction: column-reverse;
        }

        .story-content  {
            margin-right: 10%;
        }
        h1 {
            font-size: 133% !important;
            align-self: center !important;
            text-align: center !important;
        }

        p{
            font-size: 90% !important;
            align-self: center !important;
            text-align: center !important;
        
        }
        li, ul {
            font-size: 90% !important;
            text-align: left !important;
        }
        button {
            font-size: 90% !important;
        
        }

        .story-content {
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .story-side {
            display: none;
        }
        .light-img2 {
            display: flex !important;
            align-items: center !important;
            justify-content: center !important;
        }
        .light-img {
            display: none;
        }

      }
    
/*----------------------------------------PAGE1 CODE ENDS HERE ----------------------------------------*/
/*PAGE 2--------------------------------------------*/
.nav-text{
    margin-left: 10%;
    margin-right: 10%;
    padding-top: 80px;
    text-align: left;
    font-size: var(--navsize);
    color: var(--hovercolor);
    display: flex;


}

.dark-head {
    margin-left: 10%;
    margin-right: 10%;
    padding-top: 10px;
    text-align: left;
    font-size: var(--fontheader);
    color: var(--darkcolor);
}

.dark-desc {
    text-align: left;
    font-size: 120%;
    margin-top: 5px;
    color: var(--darkcolor);
    margin-bottom: 15px;
}

.dark-div {
    margin-left: 10%;
    margin-right: 10%;
    display: flex;
    justify-content: space-between;
    
}

.dark-button {
    background-color: var(--darkcolor);
    color: var(--lightcolor);
    border: none;
    padding: 10px;
    width: 90%;
    height: 20%;
    align-self: center;
    border-radius: 7px;
    font-size: 150%;
    margin-left: 18px;
}
.dark-button:hover {
    cursor: pointer;
    transform: scale(1.02);
    box-shadow: 2px 2px 5px var(--hover2color);
    background-color: var(--darkercolor);
}

.light-container {
    background-color: var(--darkcolor);
}

.light-head {
    margin-right: 10%;
    padding-top: 10px;
    margin-left: 10%;
    text-align: right;
    font-size: var(--fontheader);
    color: var(--lightcolor);
}

.light-img {
    width: 180px;
    padding-left: 20px;
    height: 190px;
    object-fit: cover;
    margin-bottom: 20px;
    margin-right: 20px;
 }


 .light-img-and-desc-container {
    display: flex;
    margin-right:10%;
    margin-left: 10%;
 }

 .light-desc{
    padding-top: 10px;
    text-align: right;
    font-size: 120%;
    color: var(--lightcolor);
 }
 .light-button {
    background-color: var(--lightcolor);
    color: var(--darkcolor);
    border: none;
    padding-bottom: 50px;
    margin-top: 20px;
    width: 70%;
    height: 20%;
    border-radius: 7px;
    font-size: 150%;
 }

 .light-button:hover {
    cursor: pointer;
    transform: scale(1.02);
    box-shadow: 2px 2px 5px var(--hover2color);
    background-color: var(--lightercolor);
 }

 .loc-button {
    width: 30%;
    margin-bottom: 25px;
 }

 /*PAGE 2 ENDS HERE*/
.SeekerResumeContactForm {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 26px;
    font-size: 120%;
    color: var(--darkcolor);
    margin-bottom: 30px;
}

.SeekerResumeContactForm label {
    margin-top: 10px;
    margin-bottom: 10px;
}

.SeekerResumeContactForm input, .SeekerResumeContactForm textarea, .SeekerResumeContactForm select {
    width: 30%;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 2px solid var(--darkcolor);
}

.SeekerResumeContactForm option{
    background-color: var(--darkcolor);
    color: var(--lightcolor);
}

.SeekerResume-send-button {
    border: none;
    background-color: var(--darkcolor);
    color: var(--lightcolor);
    padding: 3px 13px;
    font-size: 120%;
    border-radius: 5px;
    cursor: pointer;
}

.SeekerResume-send-button:hover {
    background-color: var(--lightcolor);
    color: var(--darkcolor);
    border-width: 2px;
    border-color: var(--darkcolor);
}

/*------------------------------------HIRE TALENT CODE STARTS HERE ------------------------------------*/

/*------------------------------------HIRE TALENT CODE ENDS HERE ------------------------------------*/

.footer-container{
    display: flex;
    background-color: var(--darkcolor);
    width: 100%;
    justify-content: space-between;
    flex-direction: row;
    padding-left: 10%;
    padding-right: 10%;
}

.footer-container li {
    list-style: none;
    color: var(--lightcolor);
    font-size: 120%;
    margin-top: 10px;
}

.footer-bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--darkercolor);
    padding: 20px;
    flex-direction: column;
    padding-top: 30px;
}

.footer-bottom img {
    height: 110px;
    width: 200px;
}

.footer-bottom p {
    color: white;
}


.leaf-phone {
    display: none;
}

@media screen and (max-width: 980px) {
    .footer-container {
        flex-direction: column;
        align-items: center;
        padding-top: 20px;
    }
    .footer-container button{
        margin: 10px;
        background-color: var(--darkercolor);
        border: none;
        border-bottom: 2px solid var(--lightcolor);
        color: white;
    }

    .footer-nav-options {
        color: var(--light2color) !important;
    }

    .footer-container div ul {
        align-self: center;
        margin-left: 10%;
    }
    .AV-button-contact2 {
        margin-top: 0px !important;
    }

    .AI-bullet-points-list {
        margin-left: 20% !important;
    
    }

    .leaf-desktop {
        display: none;
    }
    .leaf-phone {
        display: block;
    }


}
/*-------------------------------ABOUT VALUES----------------------------*/


.AV-container {
    margin-top: 20px;
    background-color: var(--darkcolor);
}


.AV-light-head {
    margin-left: 10%;
    margin-left: 10%;
    padding-top: 10px;
    text-align: left;
    font-size: var(--fontheader);
    color: var(--lightcolor);
}

.AV-light-desc {
    text-align: left;
    font-size: 120%;
    margin-top: 5px;
    color: var(--lightcolor);
    margin-bottom: 15px;
}

.AV-values {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: 10%;
    margin-right: 10%;
}

.AV-values img {
    height: 150px;
    margin-bottom: 10px;
    object-fit: cover;
}





/*SECOND VALUES + IMAGES SECTION*/



/* relevant styles */





  .img__img {
    height: 230px;
    width: 260px;
    object-fit: cover;
    border-radius: 5px;
    border-color: var(--darkcolor);
    border-width: 6px;
    border-style: solid;
    transition: opacity 0.3s ease;
  }
.img__wrap {
    position: relative;
    height: 230px;
    width: 260px;
  }
  
  .img__description {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background:  rgba(255, 253, 253, 0.72);/*FIX: IF CHANGE COLOR*/
    color: var(--hovercolor);
    visibility: hidden;
    opacity: 0;
    padding-left: 8px;
    padding-right: 8px;
    
    border-radius: 5px;
    border-color: var(--hovercolor);
    border-width: 6px;
    border-style: solid;
  
    transition: opacity .2s, visibility .2s;
  }
  
  .img__wrap:hover .img__description {
    visibility: visible;
    opacity: 1;
  } 

  .AV-img-container {
    align-items: center;
    margin-left: 10%;
    margin-right: 10%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 34px;
    margin-bottom: 20px;
}

.img__text {
    font-size: 150%;
    color: var(--darkcolor);
    margin-top: 0px;
    margin-bottom: 0px;

}

.AV-img-container div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.AV-cont {
    display: flex;
    justify-content: space-between;
    background-color: var(--darkcolor);
}

.AV-work {
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 10px;
    margin-bottom: 20px;
}

.AV-light-button {
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 10px;
    margin-bottom: 20px;
    border: none;
    background-color: var(--lightcolor);
    color: var(--darkcolor);
    padding: 3px 20px;
    font-size: 150%;
    border-radius: 5px;
    cursor: pointer;
    flex-grow: 3;
    margin-top: 20px;
}

.AV-light-button:hover {
    background-color: var(--hovercolor);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    transform: scale(1.03);
}

.AV-dark-button {
    margin-right: 10%;
    padding: 3px 20px;
}

.AV-desc {
    text-align: right;
    margin-bottom: 20px;
}

.AV-head {
    text-align: right;
}


  


/*-------------------------------ABOUT INFO -----------------------------*/

.AI-container {
    background-color: var(--lightcolor);
}

.AI-light-head {
    color: var(--darkcolor);
    text-align: right;
    margin-left: 10%;
    margin-right: 10%;
}

.AI-light-desc {
    color: var(--darkcolor);
    text-align: right;
}

.AI-bullet {
    display: flex;
    flex-direction: column;
}





  

/*JOB SEEKERS PAGE*/
.JS-container {
    background-color: var(--darkcolor);
    margin-top: 10px;
    padding-bottom: 10px;
}

.JS-head {
    margin-left: 10%;
    margin-right: 10%;
    padding-top: 5px;
    text-align: left;
    font-size: var(--fontheader);
    color: var(--lightcolor);
}

.JS-div{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: 10%;
    margin-right: 10%;
}

.JS-desc {
    text-align: left;
    font-size: 120%;
    margin-top: 5px;
    color: var(--lightcolor);
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.JS-button {
    background-color: var(--lightcolor);
    color: var(--darkcolor);
    border: none;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    white-space: nowrap;
    font-size: 150%;
    margin-top: 30px;
    cursor: pointer;
    border-radius: 5px;
    align-self: flex-start; /* Add this line */
    padding: 10px 20px;
    margin-left: 14px;
}

.JS-button:hover {
    background-color: var(--light2color);
    color: var(--lightcolor);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    transform: scale(1.03);
}

.JS-light-container {
    background-color: var(--lightcolor);
}

.JS-light-head {
    color: var(--darkcolor);
    text-align: right;
    margin-left: 10%;
    margin-right: 10%;
}

.JS-light-desc {
    color: var(--darkcolor);
    text-align: right;
}

.JS-light-button{
    background-color: var(--darkcolor);
    color: var(--lightcolor);
    margin-right: 20px;
}

@media screen and (max-width: 980px) {
    .JS-div {
        flex-direction: column;
    }
    .JS-button {
        align-self: center;
    }
    .JS-light-div {
        flex-direction: column-reverse;
    
    }

    .nav-text{
        display: none;
    }
    .nav-text-phone {
        margin-left: 10%;
        margin-right: 10%;
        padding-top: 80px;
        text-align: left;
        font-size: var(--navsize);
        color: var(--hovercolor);
        display: flex !important;
    }

    .Page2-button {
        padding-bottom: '5px';
    }

    .AV-img-container {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0;
    }

    .AV-img-container div{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;    
    
    }

    .AV-cont {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    
    }

    .img__text {
        margin-top: 10px;
        margin-bottom: 20px;
    }

    .dark-div {
        align-items: center;
        justify-content: center;
        flex-direction: column-reverse;
        margin-bottom: 20px;
    }

    .address-and-email {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }
}

.Contact-img-head {
    color: var(--darkcolor);
}

/*EMPLOYERS*/

.EMP-div {
    padding:0%;
    margin:0%;
}

.Emp-img {
    margin: 0;
    padding: 0;
    align-self: last baseline;
}

/*RADIO BOX FOR SENDING EMAIL TO WORK FOR CLOUD RES*/

  
  .position-container {
   
    max-width: 300px;
    margin: 0 auto;
  }
  
  .position-container h3 {
    margin-bottom: 10px;
    font-size: 100%;
  }
  
  .position-container label {
    display: block;
    margin-bottom: 10px;
    font-size: 16px;
    color: var(--hovercolor);
  }
  
  .position-container input[type="radio"] {
    margin-right: 10px;
    color: var(--hovercolor);
  }






  /*------------------------------------------NAV2 CODE STARTS HERE ------------------------------------------*/


  .wrapper2{
    background: var(--darkcolor);
    position: fixed;
    width: 100%;
  }

  .wrapper2 nav{
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    height: 70px;
  }

  nav .content2 {
    display: flex;
    align-items: center;
  }

  nav .content2 .links2{
    margin-left: 80px;
    display: flex;
    
  }

  .content2 .logo2 a{
    color: var(--lightcolor);
    font-size: 30px;
    font-weight: 600;
  }

  .content2 .links2 li{
    list-style-type: none;
  }
  .content2 .links2 li a{
    color: var(--lightcolor);
    font-size: 18px;
    font-weight: 500;
    padding: 7px 18px;
    border-radius: 5px;
    transition: all 0.3s ease;
  }

  .content2 .links2 li:hover a{
    background-color: var(--hovercolor);
  }

  /*REESPONSIVE CODE STARTS*/
  @media screen and (max-width: 1250px){
    .wrapper2 nav{
      max-width: 100%;
      padding: 0 20px;
    }
    nav .content2 .links2{
      margin-left: 30px;
    }
    .content2 .links2 li a{
      padding: 8px 13px;
    }
  }

  @media screen and (max-width: 980px){
    nav .content2 .links2{
      display: block;
      position: fixed;
      background-color: var(--darkcolor);
      height: 100%;
      width: 100%;
      top: 70px;
      left: 0;
      margin-left: 0;
      max-width: 350px;
    }

    .content2 .links2 li{
      margin: 15px 20px;
    }

    .content2 .links2 li a{
      line-height: 40px;
      font-size: 20px;
        display: block;
        padding: 8px 18px;
        text-align: left;
    }
  }


  .submenus{
    color: var(--light2color) !important;
  }

  .submenus:hover{
    color: var(--lightcolor) !important;
  }
 
  
  .input {
    color: var(--hover2color);
  }

  .footer-nav-options:hover {
    transform: scale(1.04);

  }

  .talent-button:hover {
    background-color: var(--light2color);
  }


  /*ANIMATION STARTS HERE -----------------------------------------------------------------------*/
  .animation {
    background-color: var(--darkcolor);
    padding-top: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
    height: 100vh;
    overflow: hidden;
  }

  .container-animation {
    text-align: center;
}

.animation-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.logo-animation, .company-name-animation {
    position: relative;
    opacity: 0;
    animation-timing-function: ease-in-out;
    font-size: 300%;
    color: var(--lightcolor);
}

.slogan {
    font-family: "Kaushan Script", cursive;
  font-weight: 400;
  font-style: normal;
    font-size: 200%;
    color: var(--light2color);
}

.logo-animation {
    animation: slide-in-left 1.5s forwards;
}

.company-name-animation {
    animation: slide-in-right 1.5s forwards;
    margin-left: 10px;
}

.slogan-wrapper {
    margin-top: 20px;
}

.slogan {
    opacity: 0;
    animation: fade-in 2s forwards 1.5s;
}

@keyframes slide-in-left {
    from {
        left: -100%;
        opacity: 0;
    }
    to {
        left: 0;
        opacity: 1;
    }
}

@keyframes slide-in-right {
    from {
        right: -100%;
        opacity: 0;
    }
    to {
        right: 0;
        opacity: 1;
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@media (max-width: 980px) {
    .animation-wrapper {
        flex-direction: column;
    }

    .company-name-animation {
        margin-left: 0;
        margin-top: 10px;
        font-size: 130%;
  }
  .slogan {
    font-size: 100%;
  }

.img-animation {
    white-space: nowrap;
    margin-top: 0px;
}

.addedstyling14 {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
}

.addedstyling15 {
    margin-left: 0;
    margin-right: 60px;
}

.addedstyling13 {
    margin-left: 40px;
}


}

/*EXTRA CLASSES ---------------------------------------------------------------------------------------*/
.dummy-class-1{
    text-align: left;
}

.dummy-class-2 {
    align-self: center;
}

.dummy-class-3 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.dummy4 {
    width: 210px;
    height: 200px; 
    margin-top: 10px;
    margin-bottom: 10px;
}

.dummy5 {
    display: flex; 
    align-items: center;
    flex-direction: column;
}

.dummy6 {
    align-self: center;
    white-space: nowrap; 
    padding-top: 5px;
    margin-bottom: 20px;
}

.dummy7 {
    padding-top: 15px;
}

.dummy8 {
    display: none;
}

.dummy9 {
    width: 140px;
    height: 160px; 
    margin-top: 10px;
    margin-bottom: 10px; 
}

.dummy10 {
    margin-right: 10%;
}

.dummy11 {
    margin-left: 0; 
    display: flex; 
    align-items: center; 
    flex-direction: column;
}

.dummy12 {
    width: 275px;
    height: 205px;
    padding-bottom: 19px;
}

.addedstyling1 {
    padding-top: 15px;
}

.addedstyling2 {
    display: none;
}

.addedstyling3 {
    width: 210px;
    height: 150px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.addedstyling4 {
    width: 300px;
    height: 200px;
}

.addedstyling5 {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.addedstyling6 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.addedstyling7 {
    width: 220px;
    height: 160px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.addedstyling8 {
    margin-right: 10%;
}

.addedstyling9 {
    margin-left: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.addedstyling10 {
    margin-left: -15px;
}

.addedstyling11 {
    width: 360px;
    height: 275px;
    padding-bottom: 19px;
    margin-top: 70px;
}

.addedstyling12 {
    width: 150px;
    height: 150px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.addedstyling13 {
    margin-right: 10%;
    margin-bottom: 20px;
}

.addedstyling14 {
    padding-top: 25px;
    padding-bottom: 20px;
    background-color: white;
}

.addedstyling15 {
    margin-left: 10%;
    margin-bottom: 20px;
}

.addedstyling16 {
    display: flex;
    margin-left: 10%;
    margin-right: 10%;
    justify-content: space-around;
    align-items: center;
}

.addedstyling17 {
    display: flex;
    align-items: flex-end;
}

.addedstyling18 {
    height: 60px;
}

.addedstyling19 {
    margin: 0;
    padding: 0;
    margin-left: 10px;
}

.addedstyling20 {
    height: 60px;
    margin-bottom: 0;
}

.addedstyling21 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.addedstyling22 {
    padding-top: 18px;
    padding-bottom: 18px;
}

.addedstyling23 {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-left: 10%;
    margin-right: 10%;
}

.addedstyling24 {
    height: 60px;
}

.addedstyling25 {
    margin: 0;
    padding: 0;
    margin-left: 10px;
}

.addedstyling26 {
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.addedstyling27 {
    margin-right: 10%;
    margin-bottom: 20px;
}

.addedstyling28 {
    padding-top: 7px;
    padding-bottom: 7px;
    margin-bottom: 15px;
}

.addedstyling29 {
    padding-bottom: 20px;
}

.addedstyling30 {
    white-space: nowrap;
}


.addedstyling31 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.addedstyling32 {
    background-color: white;
    margin-top: 0;
    padding-top: 30px;
}

.addedstyling33 {
    padding-bottom: 10px;
}

.addedstyling34 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.addedstyling35 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.addedstyling36 {
    padding-top: 15px;
    padding-bottom: 25px; 
}

.addedstyling37 {
    display: flex; 
    margin-left: 10%;
    margin-right: 10%;
    justify-content: space-around;
    align-items: center;
}

.addedstyling38 {
    display: flex; 
    align-items: flex-end;
}

.addedstyling39 {
    height: 60px; 
    margin-left: 25px;
}

.addedstyling40{
    margin:0px;
    padding: 0px; 
    margin-left: 10px;
}

.addesstyling41 {
    height: 60px;
    margin-bottom: 0;
}

.addedstyling42 {
    margin:0px;
    padding: 0px;
    margin-left: 10px;
}

.addedstyling43 {
    margin-left: 10%;
    margin-right: 10%;
}

.addedstyling44 {
    height: 145px;
}

.addedstyling45 {
    margin-bottom: 15px;
    font-size: 78.5%;
}

.addedstyling46 {
    display: none;
}

.addedstyling47 {
    height: 245px;
}

.addedstyling48 {
    display: none;
}

.addedstyling49 {
    width: 150px;
    height: 100px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.addedstyling50 {
    margin-right: 10%;
}

.addedstyling51 {
    justify-content: space-between;
}

.addedstyling52 {
    margin-left: 0;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.addedstyling53 {
    margin-right: 70px;
    width: 200px;
    height: 130px;
    padding-bottom: 19px;
}

.addedstyling54 {
    text-align: left;
}

.addedstyling55 {
    display: none;
}

.addedstyling56 {
    width: 140px;
    height: 150px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 25px;
    margin-left: 10px;
}

.addedstyling57 {
    padding-top: 20px;
}

.addedstyling58 {
    height: 175px;
    position: sticky;
    top: 0;
    margin-left: 10px;
}

.addedstyling59 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.addedstyling60 {
    padding-top: 20px;
}

.addedstyling61 {
    display: none;
}

.addedstyling62 {
    width: 150px;
    height: 100px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.addedstyling63 {
    margin-right: 10%;
}

.addedstyling64 {
    margin-left: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.addedstyling65 {
    width: 200px;
    height: 130px;
    padding-bottom: 19px;
}

.addedstyling66 {
    text-align: left;
    padding-bottom: 0;
    margin-bottom: 0;
}

.addedstyling70 {
    display: none;
}

.addedstyling71 {
    display: block;
}

.addedstyling72 {
    cursor: pointer;
}

.addedstyling73 {
    color: #a1b5c3;
}

.addedstyling74 {
    display: block;
}

.footer-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0;
}

.addedstyling75 {
    position: relative;
}

.addedstyling76 {
    height: 100px;
}

.addedstyling77 {
    padding-left: 10%;
    padding-right: 10%;
}

.addedstyling78 {
    position: absolute;
    top: 0;
    padding-top: 40px;
    height: 100%;
    width: 100%;
    left: 0;
}

.addedstyling79 {
    height: 100%;
    width: 100%;
}

.addedstyling80 {
    margin-left: 10%;
    margin-right: 10%;
}

.addedstyling81 {
    display: block;
    margin-top: 20px;
}

.addedstyling82 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.addedstyling83 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.addedstyling84 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.addedstyling85 {
    margin-top: 20px;
}

.addedstyling86 {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
}

.addedstyling87 {
    align-self: center;
    margin-bottom: 35px;
}

.addedstyling88 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.addedstyling89 {
    margin-top: 10px;
}

.addedstyling90 {
    white-space: nowrap;
}

.addedstyling91 {
    margin-bottom: 25px;
    margin-top: 20px;
}

.addedstyling92 {
    text-align: left;
}

.addedstyling93 {
    text-align: left;
}

.dummy77 {
    height: 400px;
}

.addedstyling94 {
    padding-top: 20px;
}

.addedstyling95 {
    display: none;
}

.addedstyling96 {
    width: 165px;
    height: 200px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.addedstyling97 {
    width: 250px;
    height: 200px;
}

.addedstyling98 {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.addedstyling99 {
    align-self: center;
    white-space: nowrap;
}

.addedstyling100 {
    padding-top: 20px;
}

.addedstyling101 {
    padding-bottom: 30px;
}

.addedstyling102 {
    display: none;
}

.addedstyling103 {
    width: 210px;
    height: 200px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.addedstyling104 {
    width: 260px;
    height: 200px;
}

.addedstyling105 {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.addedstyling106 {
    align-self: center;
    white-space: nowrap;
}

.addedstyling107 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.addedstyling108 {
    padding-top: 20px;
}

.addedstyling109 {
    text-align: left;
}
.addedstyling110 {
    padding-top: 20px;
}

.addedstyling111 {
    display: none;
}

.addedstyling112 {
    width: 150px;
    height: 200px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.addedstyling113 {
    margin-left: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.addedstyling114 {
    width: 190px;
    height: 220px;
    padding-bottom: 19px;
}

.addedstyling115 {
    padding-top: 20px;
}

.addedstyling116 {
    text-align: left;
}

.addedstyling117 {
    color: '#a1b5c3';
}

/*Areeb's Code Starts Here*/
.auth-section { /* Styling for the authentication section */
    min-height: 100vh;
    min-width: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-size: cover; */
    background-size : 100% 100%;
    background-position: center;
    /* background-image: url('./LeafBorder.png'); */
    background-color: #00365C;
}

.auth-container { /* Styling for the container of the authentication section */
    width: 100%;
    max-width: 28rem;
    padding: 1rem;
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    border: 1px solid #ffffff;
}

.auth-logo { /* Styling for the logo of the authentication section */
    text-align: center;
    padding: 0.5rem 0;
}

.auth-logo img { /* Styling for the image of the logo of the authentication section */
    width: 18rem;
    height: 9rem;
    margin: 0 auto;
}

.auth-form { /* Styling for the form of the authentication section */
    text-align: center;
    color: #00365C;
    font-weight: bold;
}

.auth-form p { /* Styling for the paragraph of the form of the authentication section */
    margin-bottom: 0.5rem;
}

.auth-button-container { /* Styling for the button container of the authentication section */
    margin-bottom: 1rem;
    text-align: center;
}

.auth-button { /* Styling for the button of the authentication section */
    width: 100%;
    border-radius: 0.25rem;
    padding: 0.5rem 0;
    font-size: 0.875rem;
    font-weight: 500;
    text-transform: uppercase;
    color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.15s ease-in-out;
    background: #00365C;
}

.auth-button:hover { /* Styling for the hover effect of the button of the authentication section */
    background: #04273F;
}

@media (max-width: 480px){ /* Styling for the responsiveness of the authentication section */
    .auth-section{
        width: 0rem;
    }

    .auth-logo img {
        width: 15rem;
        height: 7.5rem;
    }
}

