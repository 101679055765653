/* Styling for the Smart Recruitment App Homepage */
.app-container { /* Styling for the container of the app */
    padding: 2rem;
    min-height: 100vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    /* background-image: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url('/assets/images/mzm_techbg.jpg'); */
    /* background-image: url('/assets/images/LeafBorder.png'); */
    background-color: white;
}

.app-title {
    display: flex;
    justify-content: space-between; /* Distributes space between and around content items along the main-axis. */
    align-items: center; /* Aligns items along the cross axis. */
    padding: 0 2rem; /* Padding on both sides of the container */
    margin-top: 3rem;
}

.title {
    flex-grow: 1;            /* Allows the title to take up any extra space */
    font-size: 2rem;
    color: #00365C;
    font-weight: bold;
    text-align: center;        /* Aligns the title to the left */
    margin-left: 6rem;
}

.logout-button{
    margin-right: 0.7rem;      /* Adds margin to the right of the button */
    background-color: #00365C;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    display: inline-flex;    /* Ensures the button is treated as an inline element with flex properties */
    align-items: center;
    transition: background-color 0.15s ease-in-out;
    text-transform: uppercase;
}

.submitted-container { /* Styling for the container of the submitted section */
    margin-top: 2rem;
    max-width: 70rem;
    margin-left: auto;
    margin-right: auto;
    background-color: #335E7C;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    padding: 1.5rem;
    border: 1px solid rgba(255, 255, 255, 0.9);
}

.font-large { /* Styling for the large font size */
    font-size: 1.5rem;
}

.font-medium { /* Styling for the medium font size */
    font-weight: 500;
}

.text-white { /* Styling for the white text color */
    color: white;
}

/* Job Form Component */
.job-form-section { /* Styling for the section containing the job form */
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center;
}

.job-form-container { /* Styling for the container of the job form */
    width: 100%;
    max-width: 70rem;
    height: auto;
    padding: 1.5rem;
    background-color: #335E7C;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    border: 1px solid #ffffff;
}

.job-form-label { /* Styling for the label of the job form */
    display: block;
    font-size: 1rem;
    font-weight: 500;
    /* margin-top: 0.5rem; */
    color: white;
    /* margin-bottom: 0.5rem; */
}

.job-form-input,
.job-form-textarea { /* Styling for the input and textarea of the job form */
    width: 100%;
    padding: 0.5rem 1rem;
    border: 1px solid #d1d5db;
    border-radius: 0.25rem;
    margin-top: 0.5rem; 
    outline: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.job-form-textarea{
    height: 15rem;
}

.job-form-input:focus,
.job-form-textarea:focus { /* Styling for the focused state of the input and text area of the job form */
    border-color: #04273F;;
    box-shadow: 0 0 0 2px #04273F;
}

.job-form-button, .reset-button { /* Common styling for both buttons */
    width: 50%; /* Each button takes up half the width */
    padding: 0.5rem 0;
    font-size: 0.875rem;
    font-weight: 500;
    text-transform: uppercase;
    margin-top: 1rem;
    margin-left: 0.5rem;
    color: white;
    border-color: white;
    border-radius: 0.25rem; /* Rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.15s ease-in-out;
    background: #00365C;
    display: inline-block; /* Ensures buttons are in a line */
    text-align: center; /* Centers text within the buttons */
}

.button-group { /* Styling for the group of buttons */
    display: flex;
    /* justify-content: center; */
    margin-top: 1rem;
}

/* .job-form-button { Styling for the button of the job form
    width: 100%;
    padding: 0.5rem 0;
    font-size: 0.875rem;
    font-weight: 500;
    text-transform: uppercase;
    margin-top: 1rem;
    color: white;
    border-radius: 0.25rem;
    border-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.15s ease-in-out;
    background: #00365C;
} */

.job-form-button:hover { /* Styling for the hover state of the button of the job form */
    background: #04273F;
}

/* Boolean String Component */
.button-container { /* Styling for the container of the button section */
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.boolean-string-button { /* Styling for the button that processes the boolean string */
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    transition: background-color 0.15s ease-in-out;
    margin-left: 0.5rem;
    padding: 0.5rem 1rem;
    display: flex;
    border-color: white;
    align-items: center;
    background: #00365C;
    text-transform: uppercase;
}

.boolean-string-button:hover { /* Styling for the hover state of the button that processes the boolean string */
    background: #04273F;
}

.processed-string-container { /* Styling for the container of the processed string section */
    margin-top: 1.75rem;
    border-radius: 0.25rem;
}

.processed-string-title { /* Styling for the title of the processed string section */
    font-size: 1.25rem;
    color: white;
    font-weight: bold;
    margin-top: 1rem;
}

.processed-string-textarea { /* Styling for the text area that displays the processed string */
    width: 100%;
    padding: 0.5rem 0.75rem;
    border: 1px solid #d1d5db;
    border-radius: 0.25rem;
    outline: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    height: 10rem;
}

.processed-string-textarea:focus { /* Styling for the focused state of the text area that displays the processed string */
    border-color: #00365C;
    box-shadow: 0 0 0 2px #04273F;
}

.copy-button { /*Styling for the button that copies to clipboard */
    background-color: #32a852;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    gap: 0.5rem;
    display: flex;
    align-items: center;
    transition: background-color 0.15s ease-in-out;
    background: #00365C;
}

.copy-button:hover { /* Styling for the hover state of the button that copies to clipboard */
    background: #04273F;
}

.boolean-buttons{
    display: flex;
    gap: 1.5rem;
}

.save-everything{
    cursor: pointer;
    font-size: 2.5rem;
    color: white;
    transition: color 0.15s ease-in-out;
}

.save-everything:hover{
    color: #04273F;
}

/* Tech Requirements Component */
.tech-requirements-container { /* Styling for the container of the tech requirements component */
    margin-bottom: 1.5rem;
}

.tech-requirements-heading{
    display: flex;
    align-items: center;
    justify-content: space-between
}

.tech-requirements-title { /* Styling for the title of the tech requirements component */
    font-size: 1.25rem;
    color: white;
    font-weight: bold;
    /* margin-bottom: 1rem; */
    align-self: flex-start;
    margin: 0; /* Remove default margins */
    padding: 0; /* Remove default padding */
}

.tech-requirements-button { /* Styling for the button that updates the weightage of the tech requirements */
    padding: 0.5rem 1rem;
    background-color: #00365C; 
    color: white;
    border-radius: 0.25rem;
    transition: background-color 0.15s ease-in-out;
    margin-bottom: 1rem;
    display: flex;
    align-items: right;
    justify-content: center;
    gap: 0.5rem;
    text-transform: uppercase;
}

.left-side-buttons{
    gap: 2rem;
    display: inline-flex
}

.tech-requirements-button:hover { /* Styling for the hover state of the button that updates the weightage of the tech requirements */
    background-color: #04273F;
}

.tech-requirements-list { /* Styling for the list */
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.tech-requirements-item { /* Styling for the item in the list */
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    font-size: small;
    transition: opacity 0.3s ease-in-out;
    opacity: 1;
    /* transition: transform 0.3s ease-in-out; */
    padding: 0.5rem;
    border: 3px solid #04273F;
    border-radius: 0.25rem;
    margin-bottom: 0.5rem;

}

.tech-requirements-item.dragging {
    /* background-color: #f0f0f0; Light grey background */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    opacity: 0.8; /* Slightly transparent */
    border: 3px solid #04273F;
}

/* Styling for the placeholder */
.tech-requirements-item.placeholder {
    background-color: #e0e0e0; /* Slightly darker grey background */
    opacity: 0.5; /* More transparent */
    border: 3px solid #04273F;
}

.drag-handle {
    cursor: grab;
    padding: 0.5rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 0rem;
    color: white;
}

.drag-handle:active {
    cursor: grabbing;
}

/* .tech-requirements-item.swapping {
    transform: translateY(-10px);
} */


/* .tech-requirements-item:hover{
    cursor: grab;
}

.tech-requirements-item:active{
    cursor: grabbing;
} */

.tech-requirements-input{ /* Styling for the Boolean name input of each row */
    width: 19%;
    padding: 0.5rem 1rem;
    border: 1px solid #d1d5db;
    border-radius: 0.25rem;
    outline: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.tech-requirements-value { /* Styling for the boolean value of each row */
    width: 90%;
    padding: 0.5rem 1rem;
    border: 1px solid #d1d5db;
    border-radius: 0.25rem;
    outline: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.tech-requirements-weightage { /* Styling for the weightage value of each row */
    width: 15%;
    padding: 0.5rem 1rem;
    border: 1px solid #d1d5db;
    border-radius: 0.25rem;
    outline: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.tech-requirements-weightage.changed{
    color: #EE4B2B;
}

.tech-requirements-value:focus { /* Styling for the focused state of the boolean value of each row */
    border-color: #04273F;
    box-shadow: 0 0 0 2px #04273F;
}

.tech-requirements-weightage:focus { /* Styling for the focused state of the weightage value of each row */
    border-color: #04273F;
    box-shadow: 0 0 0 2px #04273F;
}

.weightage-button{
    padding: 0.5rem 1rem;
    background-color: #00365C;
    color: white;
    border-radius: 0.25rem;
    border-color: white;
    transition: background-color 0.15s ease-in-out;
    margin-bottom: 1rem;
    margin-top: 1rem;
    /* display: flex; */
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    text-transform: uppercase;
    /* height: 150%; */
}

.weightage-button:hover{
    background-color: #04273F;
}

.tech-requirements-span { /* Styling for the span of each row */
    font-weight: 600;
    white-space: nowrap;
    width: 15%;
    display: flex;
    color: white;
}

.tech-requirements-buttons { /* Styling for the buttons(minus and save) of each row */
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.tech-requirements-add { /* Styling for the add row button */
    display: flex;
    align-items: center;
    gap: 2rem;
    margin-top: 1rem;
}

.updown-button{
    font-size: 1.5rem;
    cursor: pointer;
    color: white;
    transition: color 0.15s ease-in-out;
}

.updown-button:hover { /* Styling for the hover state of the icon button */
    color: #04273F;
}

.swap-buttons{
    /* display: flex; */
    gap: 0.25rem;
}

.icon-button { /* Styling for the icon button */
    cursor: pointer;
    font-size: 2rem;
    color: white;
    transition: color 0.15s ease-in-out;
}

.icon-button:hover { /* Styling for the hover state of the icon button */
    color: #04273F;
}

.left-button{
    cursor: pointer;
    font-size: 2rem;
    color: white;
    transition: color 0.15s ease-in-out;
}

.left-button:hover{
    color: #04273F;
}

.react-tooltip {
    max-width: 800px; /* Set a fixed or max width for the tooltip */
    white-space: pre-wrap; /* Ensure the content wraps correctly */
    word-wrap: break-word; /* Break long words if necessary */
    background-color: #04273F;
    color: #ffffff;
    /* padding: 0.5rem;
    border-radius: 4px;
    font-size: 0.875rem; Adjust font size */
}

.custom-tooltip {
    max-width: 800px; /* Set a fixed or max width for the custom tooltip */
    white-space: pre-wrap; /* Ensure the content wraps correctly */
    word-wrap: break-word; /* Break long words if necessary */
    color: #ffffff;
    padding: 0.5rem;
    border-radius: 4px;
    font-size: 0.5rem; /* Adjust font size */
}

.search-container {
    display: flex;
    align-items: center;
    margin-top: 1rem;
}

/* Styling for the search input */
.search-input {
    padding: 0.5rem;
    margin-right: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    max-width: 165px;
}

/* Styling for the search button */
.search-button {
    padding: 0.5rem 1rem;
    background-color: #00365C;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 0.5rem;
}

.search-button:hover {
    background-color: #002a4a;
}

/* Styling for the search result */
.search-result {
    /* margin-left: 1rem; */
    padding: 0.5rem;
    /* border: 1px solid #ccc;
    background-color: #f9f9f9; */
    /* font-size: 0.700rem; Minimize font size */
    display: flex;
    align-items: center;
    width: 200%;
    /* max-width: 300px; */
}

.b-value{
    padding: 0.5rem 1rem;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    font-size: 0.75rem; /* Minimize font size */
    display: flex;
    align-items: center;
    width: 200%;
}

/* Styling for the add and cancel buttons */
.addcancel-buttons {
    display: flex;
    align-items: center;
}

.add-button, .cancel-button {
    padding: 0.5rem 1rem;
    margin-right: 0.5rem;
    border: white;
    border-radius: 4px;
    cursor: pointer;
}

.add-button {
    background-color: #00365C;
    color: white;
}

.add-button:hover {
    background-color: #04273F;
}

.cancel-button {
    background-color: #00365C;
    color: white;
}

.cancel-button:hover {
    background-color: #04273F;
}

.search-results {
    margin-top: 20px;
  }
  
  .search-results h3 {
    font-size: 1.5rem;
    /* margin-bottom: 10px; */
  }
  
  .search-result-item {
    border: 2px solid #04273F;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #f9f9f9;
  }
  
  .search-result-detail {
    margin: 5px 0;
    font-size: 1rem;
  }

.tech-requirements-search{
    margin-top: 20px;
}

.tech-requirements-search h3 {
    font-size: 1.5rem;
}

.tech-requirements-retrieve{
    display: flex;
    gap: 1rem;
    margin-top: 0px;
}

.candidates{
    display: inline-flexbox;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination-button {
    margin: 0 5px;
    padding: 5px 10px;
    border: 1px solid #ccc;
    background-color: #00365C; 
    cursor: pointer;
  }
  
  .pagination-button.active {
    background-color: #04273F;
    color: white;
    border-color: #04273F;
  }
  .num-candidates-input {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.num-candidates-input label {
    font-size: 1rem;
    font-weight: 500;
    color: white;
    margin-right: 0.5rem;
}

.num-candidates-input input {
    width: 6rem;
    padding: 0.5rem;
    border: 1px solid #d1d5db;
    border-radius: 0.25rem;
    outline: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.num-candidates-input input:focus {
    border-color: #04273F;
    box-shadow: 0 0 0 2px #04273F;
}

.experience-container { /* Styling for the container of the experience component */
    margin-bottom: 1.5rem;
}

.experience-label { /* Styling for the label of the experience component */
    font-size: 1.25rem;
    color: white;
    font-weight: bold;
}

.experience-grid { /* Styling for the grid layout of the experience component */
    padding-top: 1.5rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
}

.experience-input-container { /* Styling for the container of the input section for the years of experience */
    margin-bottom: 1rem;
}

.experience-input-label { /* Styling for the label of the input section for the years of experience */
    display: block;
    font-size: 0.875rem;
    color: white;
    font-weight: 500;
    margin-bottom: 0.5rem;
}

.experience-input { /* Styling for the input section for the years of experience */
    width: 100%;
    padding: 0.5rem 1rem;
    border: 1px solid #d1d5db;
    border-radius: 0.25rem;
    outline: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.experience-input:focus { /* Styling for the focused state of the input section for the years of experience */
    border-color: #32a852;
    box-shadow: 0 0 0 2px rgba(50, 168, 82, 0.5);
}


/*Responsive CSS styling for the Smart Recruitment App Homepage */

@media (max-width: 1200px) {
    .app-container {
        padding: 1rem;
        min-height: 60vh;
    }

    .app-title {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 1rem;
        margin-top: 70px;
    }
    .title {
        font-size: 1.25rem;
        margin-left: 5rem;
        text-align: centre;
    }

    .logout-button {
        padding: 0.5rem;
        font-size: 0.75rem; /* Adjust font size for smaller screens */
        margin-top: 0rem;
        margin-right: 0rem;
        margin-left: 1rem;
    }

    .react-tooltip {
        max-width: 500px; /* Set a fixed or max width for the tooltip */
        white-space: pre-wrap; /* Ensure the content wraps correctly */
        word-wrap: break-word; /* Break long words if necessary */
    }

    /* .num-candidates-input {
        display: flex;
        align-items: center;
        margin-top: 1rem;
        margin-bottom: 1rem;
        width: 20%;
    }
    
    .num-candidates-input label {
        font-size: 1rem;
        font-weight: 500;
        color: white;
        margin-right: 0.5rem;
    }
    
    .num-candidates-input input {
        width: 4rem;
        padding: 0.5rem;
        border: 1px solid #d1d5db;
        border-radius: 0.25rem;
        outline: none;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
    
    .num-candidates-input input:focus {
        border-color: #04273F;
        box-shadow: 0 0 0 2px #04273F;
    } */
    .tech-requirements-retrieve{
        display: flex;
        gap: 1rem;
        margin-top: 0px;
    }


    /* .title {
        margin-left: 0;
        text-align: left;
    }

    .logout-button {
        margin-top: 1rem;
    } */
}

@media (max-width: 768px) {
    .app-container {
        padding: 1rem;
        min-height: 60vh;
    }

    .app-title {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 1rem;
    }
    .title {
        font-size: 1.25rem;
        margin-left: 0;
        text-align: center;

    }

    .logout-button {
        padding: 0.5rem;
        font-size: 0.75rem; /* Adjust font size for smaller screens */
        margin-top: 0rem;
        margin-right: 0rem;
        margin-left: 1rem;
    }

    .submitted-container {
        padding: 1rem;
    }

    .job-form-container {
        padding: 1rem;
    }

    .job-form-textarea {
        height: 10rem;
    }

    .react-tooltip {
        max-width: 430px; /* Set a fixed or max width for the tooltip */
        white-space: pre-wrap; /* Ensure the content wraps correctly */
        word-wrap: break-word; /* Break long words if necessary */
    }
    .tech-requirements-retrieve{
        display: flex;
        gap: 1rem;
        margin-top: 0px;
    }
}

@media (max-width: 480px) {
    .app-container{
        min-height: 60vh;
    }
    .app-title {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 1rem;
        margin-top: 70px;
    }
    .title {
        font-size: 1.25rem;
        margin-left: 0;
        text-align: left;
    }

    .logout-button {
        padding: 0.5rem;
        font-size: 0.75rem; /* Adjust font size for smaller screens */
        margin-top: 0rem;
        margin-right: 0rem;
        margin-left: 1rem;
    }

    .job-form-label {
        font-size: 0.75rem;
    }

    .job-form-input,
    .job-form-textarea {
        padding: 0.5rem;
    }

    .job-form-button {
        font-size: 0.75rem;
        padding: 0.5rem;
    }

    .boolean-string-button {
        padding: 0.5rem;
    }

    .processed-string-title {
        font-size: 1rem;
    }

    .processed-string-textarea {
        height: 8rem;
    }

    .copy-button {
        padding: 0.5rem;
    }

    .tech-requirements-title {
        font-size: 1rem;
    }

    .tech-requirements-button {
        padding: 0.5rem;
        
    }

    .tech-requirements-input,
    .tech-requirements-value,
    .tech-requirements-weightage {
        padding: 0.5rem;
    }

    .icon-button {
        font-size: 1.5rem;
    }
    
    .left-side-buttons{
        align-self: flex-end;
        justify-content: flex-end;
        align-items: flex-start;
        gap: 14.5rem;
    }

    .left-button{
        font-size: 1.5rem;
        align-self: flex-end;
        justify-content: flex-end;
        margin-top: 4px;
    }

    .tech-requirements-item {
        flex-direction: column;
        align-items: flex-start;
    }
    
    .icon-button{
        margin-bottom: 0rem;
    }
    .tech-requirements-input,
    .tech-requirements-value,
    .tech-requirements-weightage {
        width: 100%;
    }

    .tech-requirements-buttons {
        align-self: flex-end;
    }

    .react-tooltip {
        max-width: 370px; /* Set a fixed or max width for the tooltip */
        white-space: pre-wrap; /* Ensure the content wraps correctly */
        word-wrap: break-word; /* Break long words if necessary */
    }
    .swap-buttons{
        display: flex;
        gap: 0.25rem;
    }
    .search-container {
        flex-direction: column;
        align-items: flex-start;
    }

    .search-input,
    .search-button,
    .search-result,
    .addcancel-buttons {
        margin: 0.5rem 0;
        width: 100%;
    }

    .addcancel-buttons {
        justify-content: space-between;
    }

    .add-button, .cancel-button {
        width: 48%;
        font-size: 1rem;
    }

    .tech-requirements-retrieve{
        display: flex;
        gap: 1rem;
        margin-top: 0px;
    }
}